import React, { useEffect, useState } from "react";
import css from "../css/Mywallet.module.css";
import Rightcontainer from "../Components/Rightcontainer";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { FaRupeeSign } from "react-icons/fa";
import { IoArrowBackOutline } from "react-icons/io5";

const Mywallet = () => {
  const history = useHistory();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  let access_token = localStorage.getItem("token");
  access_token = localStorage.getItem("token");
  const [user, setUser] = useState();
  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      window.location.reload();
      history.push("/login");
    }
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data);
      })
      .catch((e) => {
        console.log(e);
        if (e.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          history.push("/login");
        }
      });
  }, []);

  return (
    <>
      <div className="leftContainer bg-transparent">
        <div className="px-4 mt-5 pt-4">
          <div className="main_area d-flex justify-content-between align-items-center">
            <div className="back_btn">
              <Link to="/" className="btn">
                <IoArrowBackOutline/>&nbsp;Back
              </Link>
            </div>
            <div className="back_btn">
              <Link to="/transaction-history" className="btn">
                Wallet History
              </Link>
            </div>
          </div>
          <div className="back_btn my-3">
            <div className="wallet_box mt-0">
              <Link
                className={`boxes w-100 d-flex align-items-center justify-content-between`}
                to="/wallet"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <img
                    src="/Images/avatars/Avatar6.png"
                    alt="wallet"
                    width={"30px"}
                  />
                  <div
                    className={`${css.mytext} ml-2 text-dark font-weight-bold`}
                  >
                    Verified
                  </div>
                  <i
                    class="fa fa-check-circle-o text-success ml-1"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="btn bg-dark rounded">
                  Verification Completed
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* <div
          className={`${css.divider_x} XXsnipcss_extracted_selector_selectionXX snipcss0-0-0-1 tether-target-attached-top tether-abutted tether-abutted-top tether-element-attached-top tether-element-attached-center tether-target-attached-center`}
        ></div> */}

        <div className="px-4">
          <div className="card_custum_new">
            <div className="custum_title">
              Deposit Chips
            </div>
           <div className="padding_10">
           <div className="custom_card">
              यह चिप्स Win अवं Buy की गई चिप्स है इनसे सिर्फ गेम खेले जा सकते
              है, बैंक या UPI से निकाला नहीं जा सकता है
            </div>
            <div className="w-100 text-center">
            <span className="chips_design_profile">Chips</span>

              <div className="d-flex align-items-center justify-content-center">
                {/* <picture className="mr-1">
                  <img
                    width="50px"
                    src={
                      process.env.PUBLIC_URL +
                      "Images/LandingPage_img/global-rupeeIcon.png"
                    }
                    alt="chips"
                  />
                </picture> */}
                <div className="chips_design">
                
                <h6 className="price">
                  <FaRupeeSign/>
                  {user && user.Wallet_balance}
                </h6>
                </div>

              </div>
            </div>
          <div>
          <Link to="/addcase" className="px-2 my-3">
              <button className="custom_blue_btn">
                Add Chips
              </button>
            </Link>
          </div>
           </div>
          </div>

          <div className="card_custum_new mt-4">
            <div className="custum_title" >
              Winning Chips
            </div>
            <div class="w-100 text-center">
            <div className="padding_10">

          
            <div className="custom_card">
              यह चिप्स गेम से जीती हुई एवं रेफरल से कमाई हुई है, इन्हे बैंक या
              UPI में निकाला जा सकता है, इन चिप्स से गेम भी खेला जा सकता है
            </div>
            <span className="chips_design_profile">Chips</span>
            <div className="d-flex justify-content-center">
            <div className="chips_design">
                {/* <picture className="mr-1">
                  <img
                    width="50px"
                    src={
                      process.env.PUBLIC_URL +
                      "Images/LandingPage_img/global-rupeeIcon.png"
                    }
                    alt="chips"
                  />
                </picture> */}
                <h6 className="price">
                <FaRupeeSign/>
                  {user && user.withdrawAmount}
                </h6>
               
              </div>
            </div>
            <Link to="/Withdrawopt" className="px-2 my-3">
            <button className="custom_blue_btn">
                Withdraw
              </button>
            </Link>
          </div>
          </div>
        </div>
      </div>
      </div>

      <div className="rightContainer">
        <div>
          <Rightcontainer />
        </div>
      </div>
    </>
  );
};
export default Mywallet;
