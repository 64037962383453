import React, { useEffect, useRef, useState } from "react";
import "../css/layout.css";
import css from "../Modulecss/Home.module.css";
import axios from "axios";
import { NavLink, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
//import Rightcontainer from "../Components/Rightcontainer";

import BetCard from "./BetCard";
import RunningCard from "./RunningCard";
import Header from "../Components/Header";
import avtar1 from "../Avtar/Avatar1.png";
import avtar2 from "../Avtar/Avatar2.png";
import avtar3 from "../Avtar/Avatar3.png";
import avtar4 from "../Avtar/Avatar4.png";
//import { Alert } from "@mui/material";3

export default function Homepage({ walletUpdate }) {
  //const history = useHistory();
  let userID = useRef();
  const isMounted = useRef(true);
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  /// user details start

  const [user, setUser] = useState();
  const [created, setCreated] = useState([]);
  const [socket, setSocket] = useState();

  const [userAllData, setUserAllData] = useState();
  const [noticMessage, setnoticMessage] = useState("");

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data._id);
        setUserAllData(res.data);
        userID.current = res.data._id;
        setMount(true);
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response?.status === 400 || e.response?.status === 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  /// user details end

  const [game_type, setGame_type] = useState(
    useLocation().pathname.split("/")[2]
  );
  const [Game_Ammount, setGame_Ammount] = useState();

  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const ChallengeCreate = (e) => {
    let nextplay = false;
    if (game_type == "Ludo Classics Lite") {
      if (Game_Ammount >= 50 && Game_Ammount <= 200000) {
        nextplay = true;
      } else {
        Swal.fire({
          title: "Amount should be between 50 and 200000!",
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    }
    if (game_type == "Ludo Classics Pro") {
      if (Game_Ammount >= 550 && Game_Ammount <= 200000) {
        nextplay = true;
      } else {
        Swal.fire({
          title: "Amount should be between 550 and 200000!",
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    }

    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    if (nextplay) {
      setLoading(true);
      setButtonDisabled(true);
      axios
        .post(
          baseUrl + `challange/create`,
          {
            Game_Ammount,
            Game_type: game_type,
          },
          { headers }
        )
        .then((res) => {
          if (res.data.msg === "you can not create same amount challenge.") {
            Swal.fire({
              title: "you can not create same amount challenge.",
              icon: "warning",
              confirmButtonText: "OK",
            });
          } else if (res.data.msg === "you have already enrolled") {
            Swal.fire({
              title: "You have already enrolled",
              icon: "warning",
              confirmButtonText: "OK",
            });
          } else if (res.data.msg === "You can set maximum 3 battle.") {
            Swal.fire({
              title: "You can set maximum 3 battle.",
              icon: "warning",
              confirmButtonText: "OK",
            });
          } else if (res.data.msg === "Insufficient balance") {
            Swal.fire({
              title: "Insufficient balance",
              icon: "warning",
              confirmButtonText: "OK",
            });
          } else if (
            res.data.msg ===
            "Game amount should be Greater then 50 and less then 200000"
          ) {
            Swal.fire({
              title:
                "Game amount should be Greater then 50 and less then 200000",
              icon: "warning",
              confirmButtonText: "OK",
            });
          } else if (res.data.msg === "Set Battle in denomination of 50") {
            Swal.fire({
              title: "Set Battle in denomination of 50",
              icon: "warning",
              confirmButtonText: "OK",
            });
          } else if (res.data.msg === "Technical Issue, Try after an hour!") {
            Swal.fire({
              title: "Technical Issue, Try after an hour!",
              icon: "warning",
              confirmButtonText: "OK",
            });
          } else {
            // Allgames();
            socket.emit("gameCreated");
          }
          setGame_Ammount("");
        })
        .catch((e) => {
          if (e.response?.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("token");
            window.location.reload();
            setTimeout(() => {
              //  history.push("/login")
            }, 500);
          }
          if (e.response?.status === 400 || e.response?.status === 429) {
            Swal.fire({
              title: "Please refresh!",
              icon: "warning",
              confirmButtonText: "OK",
            });
          }
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            setButtonDisabled(false);
          }, 3000);
        });
    }
  };

  const [allgame, setallgame] = useState([]);
  const [mount, setMount] = useState(false);
  //const [ALL, setALL] = useState();
  const [runningGames, setRunningGames] = useState();
  const [ownRunning, setOwnRunning] = useState([]);

  const Allgames = async () => {
    // debugger;
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .get(baseUrl + `challange/all`, { headers })
      .then((res) => {
        let owenedCreated = [],
          remainingGame = [];
        res.data.forEach(function (ele) {
          if (
            ele.Created_by._id === user &&
            (ele.Status === "new" || ele.Status === "requested")
          ) {
            owenedCreated.push(ele);
          } else {
            remainingGame.push(ele);
          }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response?.status === 400 || e.response?.status === 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  const runningGame = async () => {
    // debugger;
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .get(baseUrl + `challange/running/all`, { headers })
      .then((res) => {
        let owenedRunning = [],
          remainingRunning = [];
        res.data.forEach(function (ele) {
          if (ele.Created_by && ele.Accepetd_By)
            if (
              ele.Created_by._id === userID.current ||
              ele.Accepetd_By._id === userID.current
            ) {
              owenedRunning.push(ele);
            } else {
              remainingRunning.push(ele);
            }
        });
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          //    setTimeout(() => {
          // //  history.push("/login")
          // }, 500);
        }
        if (e.response?.status === 400 || e.response?.status === 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  function winnAmount(gameAmount) {
    // debugger;
    let profit = null;
    if (gameAmount >= 50 && gameAmount <= 250) profit = (gameAmount * 10) / 100;
    else if (gameAmount > 250 && gameAmount <= 450)
      profit = (gameAmount * 10) / 100;
    else if (gameAmount > 450) profit = (gameAmount * 5) / 100;
    return gameAmount - profit;
  }

  useEffect(() => {
    WebSocket.prototype.emit = function (event, data) {
      if (this.readyState === WebSocket.OPEN)
        this.send(JSON.stringify({ event, data }));
    };
    WebSocket.prototype.listen = function (eventName, callback) {
      this._socketListeners = this._socketListeners || {};
      this._socketListeners[eventName] = callback;
    };

    let socket = new WebSocket("wss://socketseed.khelohub.com/");

    function openFunc() {
      socket.onopen = () => {
        setSocket(socket);
        socket.pingTimeout = setTimeout(() => {
          socket.close();
          setSocket(undefined);
        }, 300000 + 10000);
      };
    }

    function listenFunc() {
      socket.onmessage = function (e) {
        try {
          const { event, data } = JSON.parse(e.data);
          socket._socketListeners[event](data);
        } catch (error) {
          console.log(error);
        }
      };

      socket.listen("ping", (data) => {
        socket.emit("pong", 2);
        clearTimeout(socket.pingTimeout);
        socket.pingTimeout = setTimeout(() => {
          console.log("ping terminate works 🏩");
          socket.close();
          setSocket(undefined);
        }, 300000 + 10000);
      });
      socket.listen("recieveGame", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.Created_by)
            if (
              ele.Created_by._id === userID.current &&
              (ele.Status === "new" || ele.Status === "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });

        setCreated(owenedCreated);
        setallgame(remainingGame);
      });

      socket.listen("updateRunning", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.Created_by)
            if (
              ele.Created_by._id == userID.current &&
              (ele.Status == "new" || ele.Status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
        walletUpdate();
      });

      socket.listen("acceptor_seen", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.openBattle.forEach(function (ele) {
          if (ele.Created_by)
            if (
              ele.Created_by._id == userID.current &&
              (ele.Status == "new" || ele.Status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
        let owenedRunning = [],
          remainingRunning = [];
        data.runningBattle.forEach(function (ele) {
          if (ele.Created_by && ele.Accepetd_By)
            if (
              ele.Created_by._id == userID.current ||
              ele.Accepetd_By._id == userID.current
            ) {
              owenedRunning.push(ele);
            } else {
              remainingRunning.push(ele);
            }
        });
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
        walletUpdate();
      });

      socket.listen("resultUpdateReq", (data) => {
        let owenedRunning = [],
          remainingRunning = [];
        data.forEach(function (ele) {
          if (ele.Created_by && ele.Accepetd_By)
            if (
              ele.Created_by._id == userID.current ||
              ele.Accepetd_By._id == userID.current
            ) {
              owenedRunning.push(ele);
            } else {
              remainingRunning.push(ele);
            }
        });
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
        walletUpdate();
      });

      socket.listen("startAcepptor", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.Created_by)
            if (
              ele.Created_by._id == userID.current &&
              (ele.Status == "new" || ele.Status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
        walletUpdate();
      });

      socket.listen("challengeAccepted", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.Created_by)
            if (
              ele.Created_by._id == userID.current &&
              (ele.Status == "new" || ele.Status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
      });

      socket.listen("updateReject", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.Created_by)
            if (
              ele.Created_by._id == userID.current &&
              (ele.Status == "new" || ele.Status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
      });

      socket.listen("ongoingChallenge", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.openBattle.forEach(function (ele) {
          if (ele.Created_by)
            if (
              ele.Created_by._id == userID.current &&
              (ele.Status == "new" || ele.Status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
        let owenedRunning = [],
          remainingRunning = [];
        data.runningBattle.forEach(function (ele) {
          if (ele.Created_by && ele.Accepetd_By)
            if (
              ele.Created_by._id == userID.current ||
              ele.Accepetd_By._id == userID.current
            ) {
              owenedRunning.push(ele);
            } else {
              remainingRunning.push(ele);
            }
        });
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
      });

      socket.listen("updateDelete", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.Created_by)
            if (
              ele.Created_by._id == userID.current &&
              (ele.Status == "new" || ele.Status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
      });
    }

    function closeFunc() {
      socket.onclose = () => {
        console.log("socket disconnected wow 😡");
        if (isMounted.current) {
          clearTimeout(socket.pingTimeout);
          setSocket(undefined);
          socket = new WebSocket("wss://socketseed.khelohub.com/");
          openFunc();
          listenFunc();
          closeFunc();
        }
      };
    }

    openFunc();
    listenFunc();
    closeFunc();

    return () => {
      isMounted.current = false;
      clearTimeout(socket.pingTimeout);
      setSocket(undefined);
      socket.close();
    };
  }, []);

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      window.location.reload();
      setTimeout(() => {}, 500);
    }
    role();
    if (mount) {
      Allgames();
      runningGame();
    }
  }, [mount]);

  const AcceptChallang = (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .put(
        baseUrl + `challange/accept/${id}`,
        {
          Accepetd_By: headers,
          Acceptor_by_Creator_at: Date.now(),
        },
        {
          headers,
        }
      )
      .then((res) => {
        if (res.data.msg === "you have already enrolled") {
          Swal.fire({
            title: "You have already enrolled",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        if (res.data.msg === "Insufficient balance") {
          Swal.fire({
            title: "Insufficient balance",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else {
          Allgames(res.data);
          socket.emit("acceptGame");
        }
      })
      .catch((e) => {
        if (e.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {}, 500);
        }
        if (e.response?.status == 400 || e.response?.status == 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  const RejectGame = (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .put(
        baseUrl + `challange/reject/${id}`,
        {
          Accepetd_By: null,
          Status: "new",
          Acceptor_by_Creator_at: null,
        },
        { headers }
      )
      .then((res) => {
        socket.emit("gameRejected");
      })
      .catch((e) => {
        if (e.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response?.status == 400 || e.response?.status == 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  //delete
  const deleteChallenge = (_id) => {
    // debugger;
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .delete(baseUrl + `challange/delete/${_id}`, { headers })
      .then((res) => {
        socket.emit("deleteGame", _id);
      })
      .catch((e) => {
        if (e.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response?.status == 400 || e.response?.status == 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  ///challange/running/update/

  const updateChallenge = (_id) => {
    // debugger;
    // alert('ppppp');
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .put(
        baseUrl + `challange/running/update/${_id}`,
        {
          Acceptor_seen: true,
        },
        { headers }
      )
      .then((res) => {
        socket.emit("game_seen");
      })
      .catch((e) => {
        if (e.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response?.status == 400 || e.response?.status == 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        console.log(e);
      });
  };

  // const [roomCode, setRoomCode] = useState()

  const getPost = async (Id) => {
    if (
      game_type === "Ludo Classics Lite" ||
      game_type === "Ludo Classics Pro" ||
      game_type === "Ludo Ulta"
    ) {
      // alert(Id);
      socket.emit("roomCode", { game_id: Id, status: "running" });
      // alert(game_type);
    } else if (game_type === "Ludo Popular") {
      socket.emit("popularroomCode", { game_id: Id, status: "running" });
    }
  };

  const fetchData = async () => {
    // debugger;
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    setnoticMessage(data.noticMessage);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [data, setData] = useState([]);

  const Submit = async () => {
    try {
      const response = await fetch("https://kycseed.khelohub.com/dummyBattle", {
        method: "get",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.warn(result);

      if (result.status === "success" && Array.isArray(result.data)) {
        setData(result.data);
      } else {
        console.error("Unexpected data format or status");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    Submit();
  }, []);

  return (
    <>
      <Header user={userAllData} />
      <div
        className="leftContainer bg-transparent"
        style={{ minHeight: "100vh" }}
      >
        <div
          className={css.mainArea}
          style={{ paddingTop: "60px", minHeight: "100vh" }}
        >
          <div
            style={{ backgroundColor: "#000000" }}
            className="header_top_message"
          >
            <span className="text-light">
              Commission:5% ◉ Referral: 2% For All Games
            </span>
          </div>

          <div className="header_top_message">
            <span>{noticMessage}</span>
          </div>

          <div className="padding_10">
            <div className="bg_custum_khel">
              <span
                className={`${css.cxy} justify-content-start ${css.battleInputHeader} text-dark`}
                style={{ fontSize: "18px", textTransform: "capitalize" }}
              >
                Create a Battle!
              </span>

              <div className="setamt mx-auto d-flex my-2">
                <div className="position-relative w-100">
                  <input
                    className={css.formControl}
                    type="Number"
                    placeholder="Amount"
                    value={Game_Ammount}
                    onChange={(e) => setGame_Ammount(e.target.value)}
                  />

                  <div className="set ml-1 ">
                    {" "}
                    <button
                      className={`setbtn ${css.playButton} cxy m-1 position-absolute `}
                      style={{ top: "0", right: "0" }}
                      onClick={(e) => {
                        e.preventDefault();
                        ChallengeCreate(e);
                      }}
                      disabled={buttonDisabled}
                    >
                      {loading ? <span className="loader"></span> : "Set"}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className={css.dividerX}></div> */}

            <div className="mb-2">
              <div className="mb-3 open_battle_img d-flex align-items-center justify-content-between">
                <span className={` text-white ${css.gamesSectionTitle}`}>
                  <img
                    src={process.env.PUBLIC_URL + "/Images/sword.png"}
                    alt=""
                    width="20px"
                    className="mr-2"
                  />
                  Open Battles
                </span>
                <span
                  className={`${css.gamesSectionHeadline} text-uppercase mt-2 font-weight-bold`}
                  style={{ right: "1.5rem" }}
                >
                  <NavLink to="/Rules">
                    Rules
                    <img
                      className="ml-2"
                      src={process.env.PUBLIC_URL + "/Images/polices.png"}
                      alt="rules"
                      width={"22px"}
                    />
                  </NavLink>
                </span>
              </div>
              <div className="bid">
                {created &&
                  created.map(
                    (allgame) =>
                      allgame.Game_type == game_type && (
                        <BetCard
                          key={allgame._id}
                          allgame={allgame}
                          user={user}
                          deleteChallenge={deleteChallenge}
                          getPost={getPost}
                          RejectGame={RejectGame}
                          winnAmount={winnAmount}
                          AcceptChallang={AcceptChallang}
                          updateChallenge={updateChallenge}
                        />
                      )
                  )}
                {allgame &&
                  allgame.map(
                    (allgame) =>
                      (allgame.Status == "new" ||
                        (allgame.Status == "requested" &&
                          (user == allgame.Created_by._id ||
                            user == allgame.Accepetd_By._id)) ||
                        (allgame.Status == "running" &&
                          user == allgame.Accepetd_By._id &&
                          allgame.Acceptor_seen == false)) &&
                      allgame.Game_type == game_type && (
                        <BetCard
                          key={allgame._id}
                          allgame={allgame}
                          user={user}
                          deleteChallenge={deleteChallenge}
                          getPost={getPost}
                          RejectGame={RejectGame}
                          winnAmount={winnAmount}
                          AcceptChallang={AcceptChallang}
                          updateChallenge={updateChallenge}
                        />
                      )
                  )}
              </div>
            </div>
            {/* <div className={css.dividerX}></div> */}
            <div className="mb-2">
              <div className=" open_battle_img">
                <img
                  src={
                    process.env.PUBLIC_URL + "/Images/Homepage/intermediary.png"
                  }
                  alt=""
                  width="20px"
                />
                <span className={`ml-2 text-white ${css.gamesSectionTitle}`}>
                  Running Battles
                </span>
              </div>
              <div className="bid">
                {ownRunning &&
                  ownRunning.map((runnig) => {
                    if (
                      ((user == runnig.Accepetd_By._id
                        ? (runnig.Status === "running" &&
                            user == runnig.Accepetd_By._id &&
                            runnig.Acceptor_seen == true) ||
                          runnig.Status === "pending"
                        : (runnig.Status === "running" &&
                            user == runnig.Created_by._id) ||
                          runnig.Status === "pending") ||
                        runnig.Status == "conflict") &&
                      runnig.Game_type == game_type
                    )
                      return (
                        <RunningCard
                          key={runnig._id}
                          runnig={runnig}
                          user={user}
                          winnAmount={winnAmount}
                        />
                      );
                  })}

                {runningGames &&
                  runningGames.map((runnig) => {
                    if (
                      (user == runnig.Accepetd_By._id ||
                      user == runnig.Created_by._id
                        ? user == runnig.Accepetd_By._id
                          ? (runnig.Status === "running" &&
                              user == runnig.Accepetd_By._id &&
                              runnig.Acceptor_seen == true) ||
                            (runnig.Status === "pending" &&
                              runnig.Acceptor_status == null)
                          : (runnig.Status === "running" &&
                              user == runnig.Created_by._id) ||
                            (runnig.Status === "pending" &&
                              runnig.Creator_Status == null)
                        : runnig.Status === "running" ||
                          runnig.Status === "pending") &&
                      runnig.Game_type == game_type
                    )
                      return (
                        <RunningCard
                          key={runnig._id}
                          runnig={runnig}
                          user={user}
                          winnAmount={winnAmount}
                        />
                      );
                  })}
                <div className="open_battle p-0 new_battle_design">
                  {data.map((item) => (
                    <div
                      key={item._id}
                      className={`${css.betCard} mt-2 bg-none shadow`}
                    >
                      <div className="d-flex">
                        <div
                          className={`${css.betCardTitle} pl-3 d-flex align-items-center text-uppercase`}
                        >
                          <span className="">
                            PLAYING FOR
                            <img
                              className="mx-1"
                              // src={
                              //   process.env.PUBLIC_URL +
                              //   "/Images/LandingPage_img/global-rupeeIcon.png"
                              // }
                              src="/Images/rupee.png"
                              alt=""
                              width="21px"
                            />
                            {item.amount}
                          </span>
                        </div>
                        <div
                          className={`${css.betCardTitle} d-flex align-items-center text-uppercase`}
                        >
                          <span className="ml-auto mr-3">
                            PRIZE
                            <img
                              className="mx-1"
                              // src="/Images/LandingPage_img/global-rupeeIcon.png"
                              src="/Images/rupee.png"
                              alt=""
                              width="21px"
                            />
                            {item.price}
                          </span>
                        </div>
                      </div>

                      <div className="py-1 row">
                        <div className="pr-3 text-center col-5">
                          <img
                            src={`https://kycseed.khelohub.com/${item.createrImage}`}
                            alt="Creator"
                            width="33px"
                            height="33px"
                            style={{
                              borderTopLeftRadius: "50%",
                              borderTopRightRadius: "50%",
                              borderBottomRightRadius: "50%",
                              borderBottomLeftRadius: "50%",
                            }}
                          />
                          <div style={{ lineHeight: "1" }}>
                            <span className={css.betCard_playerName}>
                              {item.createrName}
                            </span>
                          </div>
                        </div>

                        <div className="pr-3 text-center col-2 cxy">
                          <div>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/Images/Homepage/versus.png"
                              }
                              alt=""
                              width="40px"
                            />
                          </div>
                        </div>
                        <div className="text-center col-5">
                          <img
                            src={`https://kycseed.khelohub.com/${item.accepterImage}`}
                            alt="Creator"
                            width="33px"
                            height="33px"
                            style={{
                              borderTopLeftRadius: "50%",
                              borderTopRightRadius: "50%",
                              borderBottomRightRadius: "50%",
                              borderBottomLeftRadius: "50%",
                            }}
                          />
                          <div style={{ lineHeight: "1" }}>
                            <span className={css.betCard_playerName}>
                              {item.accepterName}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
