import React, { useEffect, useState } from "react";
import "./Component-css/Downloadbutton.css?v=0.1";
import "../Components/Component-css/Header.css";
import css from "./Component-css/Nav.module.css";

const Downloadbutton = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  return (
    <div>
      <div className={`${css.box} rounded text-center`}>
      {/* <button
        onClick={onClick}
        className={`btn btn-primary btn-sm bg-transparent border-0 ${css.box} rounded p-0`}
        style={{ fontWeight: 500, backgroundColor: "#3A427D" }}
      >
        <div className="bell">
          <picture className={`${css.moneyIcon_container}`}>
            <i
              style={{
                color: "#fbc905",
                paddingRight: "5px",
                fontSize: "20px",
              }}
              className="fa-solid fa-download mr-0"
            />
          </picture>
        </div>
      </button> */}

      {/* <div className="text-center">
        <button
          onClick={onClick}
          className="btn w2 border-0"
          style={{ fontWeight: 500}}
        >
          <i className="fa fa-android text-white mr-2"></i>
          <span className="w3">
            {" "}
            DOWNLOAD OUR APP
          </span>
          <i className="fa fa-download text-white ml-2"></i>
        </button>
      </div> */}
<div className="games-section-titl">
{/* <h4>Tournaments</h4> */}
<a
  className="download-app-btn"
  href="https://lkludo.com/app/lkludo.apk"
  target="_blank"
>
  <span style={{ fontSize: 13}}  onClick={onClick}>
    <img
      width={23}
      height={23}
      src="https://img.icons8.com/flat-round/64/downloading-updates--v1.png"
      alt="downloading-updates--v1"
    />{" "}
    Download
  </span>
</a>
</div>
    </div>

    </div>

  );
};

export default Downloadbutton;
